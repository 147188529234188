import React, {useState,useRef,Fragment,useEffect} from 'react';
import moment from 'moment'
import lodash, { filter } from 'lodash'

import { Dialog, Transition } from '@headlessui/react'

const Acceuil = (props) => {

    const [open, setOpen] = useState(false)
    const cancelButtonRef = useRef(null)
    const [step, setStep] = useState(0)
    const [events,setEvents] = useState([])
    const [years,setYears] = useState([])
    const [event,setEvent] = useState({
        libelle:"",
        day:"",
        month:"",
        year:"",
        type:"unique",
        categorie:""
    })
    const [searchLibelle, setSearchlibelle] = useState("")
    const [filterCategorie, setFilterCategorie] = useState("0")
    const [isEdit, setIsEdit] = useState(false)
    const [editEvent, setEditEvent] = useState({
        libelle:"",
        day:"",
        month:"",
        year:"",
        type:"unique",
        categorie:""
    })

    useEffect(() => {
        getAllEvents()
        generateYears()
    }, []);

    const generateYears = () => {
        let tab = []
        let year_start = moment().format('YYYY');
        let year_end = moment().add(100,'y').format('YYYY'); // current year
        for (let i = year_start; i <= year_end; i++) {
           tab.push(i)
        }

        setYears(tab)
    }

    const getEventsSort = () => {
        let tps_events = lodash.cloneDeep(events)
        lodash.forEach(tps_events,(e)=>{
            e.dayBeforeEvent = dayBeforeEvent(e)
        })
        tps_events = lodash.sortBy(tps_events,(e)=>{
            return e.dayBeforeEvent
        })

        return tps_events
    }

    const dayBeforeEvent = (e) => {
        let count = 0
        if(e.type == "annuel"){
            count = moment(e.date,"DD-MM").startOf("date").diff(moment().startOf("date"),'days')
            if(count < 0 ){
                count = moment(e.date,"DD-MM").add(1, 'Y').diff(moment(),'days')
            }
            return count
        }else{
            count = moment(e.date,"DD-MM-YYYY").startOf("date").diff(moment().startOf("date"),'days')
            return count
        }
    }

    const getAllEvents = async () => {
        await fetch(`${process.env.REACT_APP_API_URL}/events/getAll`,{
            method:'POST',
            headers:{
                "Content-Type":"application/json",
            }
        })
        .then((response)=>{
            return response.json()
        })
        .then((json)=>{
            let res = lodash.forEach(json.events,(e)=>{
                e.day = e.date.split("-")[0]
                e.month = e.date.split("-")[1]
                e.year = e.date.split("-")[2] ? e.date.split("-")[2] : ""
            })
            setEvents(res)
            return json.events
        }).catch((error)=>{
            console.log(error)
        })
    }

    const addEvent = () => {
        fetch(`${process.env.REACT_APP_API_URL}/events/add`,{
            method:'POST',
            headers:{
                "Content-Type":"application/json",
            },
            body:JSON.stringify({
                event:event
            })
        })
        .then((response)=>{
            return response.json()
        })
        .then((json)=>{
            if(json.Status == "ok"){
                setOpen(false)
                getAllEvents()
            }
        }).catch((error)=>{
            console.log(error)
        })
    }

    const checkEvent = () => {
        let res = true
        if(event.type == "unique"){
            if(event.year == "" || event.day == "" || event.month == "" || event.libelle == "" || event.categorie == ""){
                res = false
            }
        }else{
            if(event.day == "" || event.month == "" || event.libelle == "" || event.categorie == ""){
                res = false
            }
        }
        console.log('RES : ',res)
        return res
    }

    const checkEditEvent = () => {
        let res = true
        if(editEvent.type == "unique"){
            if(editEvent.year == "" || editEvent.day == "" || editEvent.month == "" || editEvent.libelle == "" || editEvent.categorie == ""){
                res = false
            }
        }else{
            if(editEvent.day == "" || editEvent.month == "" || editEvent.libelle == "" || editEvent.categorie == ""){
                res = false
            }
        }
        return res
    }

    const [emojis, setEmojis] = useState([
        {
            "categorie":"birthday",
            "emoji":"🎂"
        },
        {
            "categorie":"movie",
            "emoji":"🎥"
        },
        {
            "categorie":"other",
            "emoji":"📅"
        },
        {
            "categorie":"special",
            "emoji":"⭐"
        },

    ])

    const scrollTop =()=>{
        window.scrollTo({top:0,behavior:'smooth'})
    }

    const getEmojis = (categ) => {
        let find = lodash.find(emojis,(e)=>{
            return e.categorie == categ
        })

        if(find){
            return find.emoji
        }else{
            return "🤷‍♂️"
        }

    }

    const updateEvent = () => {
        fetch(`${process.env.REACT_APP_API_URL}/events/update`,{
            method:'POST',
            headers:{
                "Content-Type":"application/json",
            },
            body:JSON.stringify({
                editEvent:editEvent
            })
        })
        .then((response)=>{
            return response.json()
        })
        .then((json)=>{
            if(json.Status == "ok"){
                setIsEdit(false)
                getAllEvents()
                setEditEvent({
                    libelle:"",
                    day:"",
                    month:"",
                    year:"",
                    type:"unique",
                    categorie:""
                })
            }
        }).catch((error)=>{
            console.log(error)
        })
    }

    console.log("Event : ",event)
    console.log("EditEvent : ",editEvent)
    return(
        <>
            <div className="px-6 pb-2 mt-4 flex flex-row">
                <span onClick={()=>{setStep(0);setOpen(true);setEvent({libelle:"",day:"",month:"",year:"",type:"unique",categorie:""})}} className='w-full bg-green-500 text-white p-1 rounded-md text-center cursor-pointer'>Ajouter une date</span>
            </div>
            <div className='px-6 flex flex-row'>
                <input
                    id="search"
                    name="search"
                    type="text"
                    placeholder={"Rechercher par le nom"}
                    value={searchLibelle}
                    onChange={(e)=>{
                        setSearchlibelle(e.target.value)
                    }}
                    className="appearance-none block w-full px-3 py-2 my-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm"
                />
            </div>
            <div className='px-6 flex flex-row'>
                <select
                        id="filterCategorie"
                        name="filterCategorie"
                        className="my-2 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        defaultValue={filterCategorie}
                        onChange={(e)=> setFilterCategorie(e.target.value)}
                    >
                        <option value="0">Toutes les catégories</option>
                        <option value="birthday">Anniversaire</option>
                        <option value="movie">Sortie film</option>
                        <option value="special">Evènement</option>
                        <option value="other">Autres</option>
                </select>
            </div>

            <div className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-1 sm:gap-x-6 md:grid-cols-2 lg:grid-cols-3 xl:gap-x-8 mt-4 p-2 px-6 mb-6">
                {getEventsSort().map((e)=>{
                    if ((e.libelle.toLowerCase().search(searchLibelle.toLowerCase()) === -1)) {
                        return null
                    }
                    if ((filterCategorie !== "0") && (e.categorie.toLowerCase().search(filterCategorie.toLowerCase()) === -1)) {
                        return null
                    }
                    return(
                        <li className="col-span-1 flex rounded-md shadow-md">
                            <div className='flex-shrink-0 flex items-center justify-center w-1/5 text-white text-sm font-medium rounded-l-md bg-gray-300'>
                                <span className='text-2xl'>{getEmojis(e.categorie)}</span>
                            </div>
                            <div className="flex flex-1 items-center justify-between truncate rounded-r-md  bg-white py-3">
                                <div className="flex-1 truncate px-4 py-2 text-sm">
                                    <dt className="text-sm font-medium text-gray-500 text-center">{e.libelle}</dt>
                                    <dd className="my-1 text-3xl font-semibold tracking-tight text-gray-900 text-center">{dayBeforeEvent(e) == 0 ? "Aujourd'hui 🥳" : dayBeforeEvent(e) < 0 ? "Terminé" : dayBeforeEvent(e) + " jours"}</dd>
                                    <dt className="text-sm font-semibold text-gray-500 text-center">{e.type == "annuel" ? moment(e.date,"DD-MM").format("DD MMM") : moment(e.date,"DD-MM-YYYY").format("DD MMM YYYY")}</dt>
                                </div>
                                <div className="flex-shrink-0 pr-2">
                                    <button
                                    type="button"
                                    onClick={()=>{setIsEdit(true);setEditEvent(e)}}
                                    className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-white bg-transparent text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    >
                                        <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"></path></svg>
                                    </button>
                                </div>
                            </div>
                        </li>
                    )
                })}
            </div>
            
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-10 " initialFocus={cancelButtonRef} onClose={setOpen}>
                    <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                            <Dialog.Panel className="relative w-full transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <div>
                                    <div className="mt-1 text-center sm:mt-5 ">
                                        <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 mb-6">
                                            Ajouter un évènement
                                        </Dialog.Title>
                                        <div className="mt-2">
                                                <fieldset>
                                                    <div className='flex flex-row justify-around my-2'>
                                                        <div className='flex flex-row'>
                                                            <input
                                                                name="notification-method"
                                                                type="radio"
                                                                defaultChecked={event.type == "unique"}
                                                                onClick={()=> setEvent({...event,type:"unique"})}
                                                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                                            />
                                                            <label  className="ml-3 block text-sm font-medium text-gray-700">
                                                            Unique
                                                            </label>
                                                        </div>
                                                        <div className='flex flex-row'>
                                                            <input
                                                                name="notification-method"
                                                                type="radio"
                                                                defaultChecked={event.type == "annuel"}
                                                                onClick={()=> setEvent({...event,type:"annuel"})}
                                                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                                            />
                                                            <label  className="ml-3 block text-sm font-medium text-gray-700">
                                                            Annuel
                                                            </label>
                                                        </div>
                                                    </div>
                                                </fieldset>
                                                <select
                                                        id="categorie"
                                                        name="categorie"
                                                        className=" my-2 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                        defaultValue={0}
                                                        onChange={(e)=> setEvent({...event,categorie:e.target.value})}
                                                    >
                                                        <option disabled={true} value="0">Choisir un type d'évènement</option>
                                                        <option value="birthday">Anniversaire</option>
                                                        <option value="movie">Sortie film</option>
                                                        <option value="special">Evènement</option>
                                                        <option value="other">Autres</option>
                                                    </select>
                                                <div>
                                                <input
                                                    id="libelle"
                                                    name="libelle"
                                                    type="text"
                                                    placeholder={"Libelle"}
                                                    value={event.libelle}
                                                    onChange={(e)=>{
                                                        setEvent({...event,libelle:e.target.value})
                                                    }}
                                                    className="appearance-none block w-full px-3 py-2 my-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm"
                                                />
                                                </div>
                                                <div className={(event.type == "annuel" ? "grid grid-cols-2 " : "grid grid-cols-3 ") + ' gap-4'}>
                                                    <div>
                                                        <select
                                                            id="location"
                                                            name="location"
                                                            className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                            defaultValue={0}
                                                            onChange={(e)=> setEvent({...event,day:e.target.value})}
                                                        >
                                                            <option disabled={true} value="0">Jour</option>
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                            <option value="5">5</option>
                                                            <option value="6">6</option>
                                                            <option value="7">7</option>
                                                            <option value="8">8</option>
                                                            <option value="9">9</option>
                                                            <option value="10">10</option>
                                                            <option value="11">11</option>
                                                            <option value="12">12</option>
                                                            <option value="13">13</option>
                                                            <option value="14">14</option>
                                                            <option value="15">15</option>
                                                            <option value="16">16</option>
                                                            <option value="17">17</option>
                                                            <option value="18">18</option>
                                                            <option value="19">19</option>
                                                            <option value="20">20</option>
                                                            <option value="21">21</option>
                                                            <option value="22">22</option>
                                                            <option value="23">23</option>
                                                            <option value="24">24</option>
                                                            <option value="25">25</option>
                                                            <option value="26">26</option>
                                                            <option value="27">27</option>
                                                            <option value="28">28</option>
                                                            <option value="29">29</option>
                                                            <option value="30">30</option>
                                                            <option value="31">31</option>
                                                        </select>
                                                    </div>
                                                    <div>
                                                        <select
                                                            id="location"
                                                            name="location"
                                                            className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                            defaultValue={0}
                                                            onChange={(e)=> setEvent({...event,month:e.target.value})}
                                                        >
                                                            <option disabled={true} value="0">Mois</option>
                                                            <option value="01">Janvier</option>
                                                            <option value="02">Février</option>
                                                            <option value="03">Mars</option>
                                                            <option value="04">Avril</option>
                                                            <option value="05">Mai</option>
                                                            <option value="06">Juin</option>
                                                            <option value="07">Juillet</option>
                                                            <option value="08">Août</option>
                                                            <option value="09">Septembre</option>
                                                            <option value="10">Octobre</option>
                                                            <option value="11">Novembre</option>
                                                            <option value="12">Décembre</option>
                                                        </select>
                                                    </div>
                                                    {event.type == "unique" ?
                                                        <div>
                                                            <select
                                                                    id="years"
                                                                    name="years"
                                                                    className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                                    defaultValue={0}
                                                                    onChange={(e)=> setEvent({...event,year:e.target.value})}
                                                                >
                                                                    <option disabled={true} value="0">Année</option>
                                                                {years.map((y) => {
                                                                    return(
                                                                        <option value={y}>{y}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    :null}
                                                </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                    <button
                                        type="button"
                                        disabled={!checkEvent()}
                                        className={(!checkEvent() ? "bg-gray-500 " : "bg-green-600 hover:bg-green-700 focus:ring-green-500 ") + " inline-flex w-full justify-center rounded-md border border-transparent  px-4 py-2 text-base font-medium text-white shadow-sm  focus:outline-none focus:ring-2  focus:ring-offset-2 sm:col-start-2 sm:text-sm"}
                                        onClick={()=>addEvent()}
                                    >
                                        Terminer
                                    </button>
                                <button
                                    type="button"
                                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none  sm:col-start-1 sm:mt-0 sm:text-sm"
                                    onClick={() => setOpen(false)}
                                    ref={cancelButtonRef}
                                >
                                    Annuler
                                </button>
                                </div>
                            </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>

            <Transition.Root show={isEdit} as={Fragment}>
                <Dialog as="div" className="relative z-10 " initialFocus={cancelButtonRef} onClose={setIsEdit}>
                    <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                            <Dialog.Panel className="relative w-full transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <div>
                                    <div className="mt-1 text-center sm:mt-5 ">
                                        <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 mb-6">
                                            Modifier un évènement
                                        </Dialog.Title>
                                        <div className="mt-2">
                                        
                                                <fieldset>
                                                    <div className='flex flex-row justify-around my-2'>
                                                        <div className='flex flex-row'>
                                                            <input
                                                                name="notification-method"
                                                                type="radio"
                                                                defaultChecked={editEvent.type == "unique"}
                                                                onClick={()=> setEditEvent({...editEvent,type:"unique"})}
                                                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                                            />
                                                            <label  className="ml-3 block text-sm font-medium text-gray-700">
                                                            Unique
                                                            </label>
                                                        </div>
                                                        <div className='flex flex-row'>
                                                            <input
                                                                name="notification-method"
                                                                type="radio"
                                                                defaultChecked={editEvent.type == "annuel"}
                                                                onClick={()=> setEditEvent({...editEvent,type:"annuel"})}
                                                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                                            />
                                                            <label  className="ml-3 block text-sm font-medium text-gray-700">
                                                            Annuel
                                                            </label>
                                                        </div>
                                                    </div>
                                                </fieldset>
                                       
                                       
                                                <select
                                                        id="categorie"
                                                        name="categorie"
                                                        className="my-2 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                        defaultValue={(editEvent.categorie !== "" && editEvent.categorie !== null) ? editEvent.categorie : "0"}
                                                        onChange={(e)=> setEditEvent({...editEvent,categorie:e.target.value})}
                                                    >
                                                        <option disabled={true} value="0">Choisir un type d'évènement</option>
                                                        <option value="birthday">Anniversaire</option>
                                                        <option value="movie">Sortie film</option>
                                                        <option value="special">Evènement</option>
                                                        <option value="other">Autres</option>
                                                </select>
                                                <div>
                                                <input
                                                    id="search"
                                                    name="search"
                                                    type="text"
                                                    placeholder={"Libelle"}
                                                    value={editEvent.libelle}
                                                    onChange={(e)=>{
                                                        setEditEvent({...editEvent,libelle:e.target.value})
                                                    }}
                                                    className="appearance-none block w-full px-3 py-2 my-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm"
                                                />
                                                </div>
                                                <div className={(editEvent.type == "annuel" ? "grid grid-cols-2 " : "grid grid-cols-3 ") + ' gap-4'}>
                                                    <div>
                                                        <select
                                                            id="location"
                                                            name="location"
                                                            className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                            defaultValue={editEvent.day}
                                                            onChange={(e)=> setEditEvent({...editEvent,day:e.target.value})}
                                                        >
                                                            <option disabled={true} value="0">Jour</option>
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                            <option value="5">5</option>
                                                            <option value="6">6</option>
                                                            <option value="7">7</option>
                                                            <option value="8">8</option>
                                                            <option value="9">9</option>
                                                            <option value="10">10</option>
                                                            <option value="11">11</option>
                                                            <option value="12">12</option>
                                                            <option value="13">13</option>
                                                            <option value="14">14</option>
                                                            <option value="15">15</option>
                                                            <option value="16">16</option>
                                                            <option value="17">17</option>
                                                            <option value="18">18</option>
                                                            <option value="19">19</option>
                                                            <option value="20">20</option>
                                                            <option value="21">21</option>
                                                            <option value="22">22</option>
                                                            <option value="23">23</option>
                                                            <option value="24">24</option>
                                                            <option value="25">25</option>
                                                            <option value="26">26</option>
                                                            <option value="27">27</option>
                                                            <option value="28">28</option>
                                                            <option value="29">29</option>
                                                            <option value="30">30</option>
                                                            <option value="31">31</option>
                                                        </select>
                                                    </div>
                                                    <div>
                                                        <select
                                                            id="location"
                                                            name="location"
                                                            className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                            defaultValue={editEvent.month}
                                                            onChange={(e)=> setEditEvent({...editEvent,month:e.target.value})}
                                                        >
                                                            <option disabled={true} value="0">Mois</option>
                                                            <option value="01">Janvier</option>
                                                            <option value="02">Février</option>
                                                            <option value="03">Mars</option>
                                                            <option value="04">Avril</option>
                                                            <option value="05">Mai</option>
                                                            <option value="06">Juin</option>
                                                            <option value="07">Juillet</option>
                                                            <option value="08">Août</option>
                                                            <option value="09">Septembre</option>
                                                            <option value="10">Octobre</option>
                                                            <option value="11">Novembre</option>
                                                            <option value="12">Décembre</option>
                                                        </select>
                                                    </div>
                                                    {editEvent.type == "unique" ?
                                                        <div>
                                                            <select
                                                                    id="years"
                                                                    name="years"
                                                                    className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                                    defaultValue={editEvent.year}
                                                                    onChange={(e)=> setEditEvent({...editEvent,year:e.target.value})}
                                                                >
                                                                    <option disabled={true} value="0">Année</option>
                                                                {years.map((y) => {
                                                                    return(
                                                                        <option value={y}>{y}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    :null}
                                                </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                
                               
                                    <button
                                        type="button"
                                        disabled={!checkEditEvent()}
                                        className={(!checkEditEvent() ? "bg-gray-500 " : "bg-green-600 hover:bg-green-700 focus:ring-green-500 ") + " inline-flex w-full justify-center rounded-md border border-transparent  px-4 py-2 text-base font-medium text-white shadow-sm  focus:outline-none focus:ring-2  focus:ring-offset-2 sm:col-start-2 sm:text-sm"}
                                        onClick={() => updateEvent()}
                                    >
                                        Modifier
                                    </button>
                                <button
                                    type="button"
                                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none  sm:col-start-1 sm:mt-0 sm:text-sm"
                                    onClick={() => setIsEdit(false)}
                                    ref={cancelButtonRef}
                                >
                                    Annuler
                                </button>
                                </div>
                            </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>

            <button onClick={scrollTop} type="button" data-mdb-ripple="true" data-mdb-ripple-color="light" class="fixed p-3 bg-primaryDark text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out bottom-5 right-5" id="btn-back-to-top">
                <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 11l7-7 7 7M5 19l7-7 7 7"></path></svg>
                </button>
            <button onClick={getAllEvents} type="button" data-mdb-ripple="true" data-mdb-ripple-color="light" class="fixed p-3 bg-primaryDark text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out bottom-5 left-5" id="btn-back-to-top">
                    <svg className='w-4 h-4' viewBox="0 0 24 24" stroke-width="1.5" fill="none" stroke="currentColor">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <path d="M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -4v4h4" />
                    <path d="M4 13a8.1 8.1 0 0 0 15.5 2m.5 4v-4h-4" />
                    </svg>   
            </button>

        </>
    )
}

export default Acceuil
