import React from 'react';
import {Router,Route,Switch} from 'react-router-dom'
import { createBrowserHistory } from 'history'


import Acceuil from './Acceuil';
const customHistory = createBrowserHistory()

//Routing de l'application web
const Routing = () => (
  
        <Router history={customHistory} >
            <Switch>
                <Route exact component={Acceuil} path="/" />
            </Switch>
        </Router>
    
)
export default Routing
